/*
 * @Author: chenxiugen@zuoyebang.com
 * @Date: 2022-02-18 19:39:10
 * @LastEditTime: 2022-02-23 12:37:51
 * @LastEditors: 陈秀根
 * @Description:
 *
 */
import { throttle } from '@/utils'
import { VNode } from 'vue'
export default {
  inserted: (el: HTMLElement, binding: any) => {
    // 初始化透明度设置为0 默认添加 animation动画
    const { init } = binding.value || {}
    switch (true) {
      case typeof init === 'string':
        el.classList.add(init)
        break
      case init === null:
        break
      default:
        el.classList.add('animate__animated')
        el.style.opacity = '0'
        break
    }
  },
  bind: (el: HTMLElement, bind: any, vnode: VNode) => {
    const onScrollHandler = throttle(() => {
      const options = bind.value || {}
      const top = el.getBoundingClientRect().top
      const viewHeight = document.body.clientHeight

      const threshold = 0 // 阈值
      let isAnimationed = el.hasAttribute('data-animationed')
      let anim = '' // // 对应的动画样式
      let limit = threshold // 距离底部多高，默认为0，即：刚出现就播放、刚离开就移除
      // 当显示或者隐藏时，对应的回调
      let onVisible: ((scrollTop: number) => void) | null = null
      let onHide: ((scrollTop: number) => void) | null = null
      if (typeof options === 'string') {
        anim = bind.value
      } else {
        const { top, bottom } = options
        limit = typeof top === 'number' ? viewHeight - top : limit
        limit = typeof bottom === 'number' ? bottom : limit
        anim = options.anim || anim
        onVisible = options.onVisible || null
        onHide = options.onHide || null
      }

      if (top <= viewHeight - limit && !isAnimationed) {
        // 已经出现、可以播放动画
        el.setAttribute('data-animationed', 'true')
        anim && el.classList.add(anim)
        isAnimationed = true
        typeof onVisible === 'function' && onVisible(top)
      }
      if (top > viewHeight && isAnimationed) {
        // 已经看不见了，还原，取消动画
        el.removeAttribute('data-animationed')
        anim && el.classList.remove(anim)
        isAnimationed = true
        typeof onHide === 'function' && onHide(top)
      }
    }, 500)

    if (!vnode.data) {
      return
    }
    vnode.data.props = Object.assign({}, vnode.data.props, {
      onScrollHandler
    })

    window.addEventListener('scroll', onScrollHandler)
    onScrollHandler()
  },
  unbind: (el: HTMLElement, binding: any, vnode: VNode) => {
    const onScrollHandler = vnode.data?.props?.onScrollHandler
    onScrollHandler && window.removeEventListener('scroll', onScrollHandler)
  }
}
