import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'

export enum DeviceType {
  Mobile = 'mobile',
  Desktop = 'desktop'
}

export interface IAppState {
  device: DeviceType
  visited: boolean
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements IAppState {
  public device = DeviceType.Desktop
  public visited = false

  @Mutation
  private TOGGLE_DEVICE(device: DeviceType) {
    this.device = device
  }

  @Mutation
  private TOGGLE_VISTED(visit: boolean) {
    this.visited = visit
  }

  @Action
  public ToggleDevice(device: DeviceType) {
    this.TOGGLE_DEVICE(device)
  }

  @Action
  public ToggleVisited(visit: boolean) {
    this.TOGGLE_VISTED(visit)
  }
}

export const AppModule = getModule(App)
