import { VNode } from 'vue'
import { checkInView } from '@/utils'
import _ from 'lodash'

export default {
  inserted: (el: HTMLElement, bind: any, vnode: VNode) => {
    const onscroll = _.throttle(() => {
      // 当前元素是否在视口内
      if (vnode.data?.on?.click && checkInView(el)) {
        const { click } = vnode.data?.on as any
        if (click) click(true)
        window.removeEventListener('scroll', onscroll)
      }
    }, 200)
    window.addEventListener('scroll', onscroll)
    onscroll()
  }
}
