/*
 * @Author: chenxiugen@zuoyebang.com
 * @Date: 2022-02-18 15:00:23
 * @LastEditTime: 2022-02-18 15:00:24
 * @LastEditors: 陈秀根
 * @Description:
 *
 */
export * from './cookies'
export * from './validate'

/**
 * 节流
 * @param fn 函数
 * @param interval 间隔时间，毫秒
 */

export function throttle<C, T extends unknown[]>(
  fn: (this: C, ...args: T) => unknown,
  interval = 200,
  immedia = false
) {
  let flag = false
  return function (this: C, ...args: T): void {
    if (!flag) {
      flag = true
      if (immedia) {
        fn.call(this, ...args) // this 报语法错误，先用 null
        setTimeout(() => {
          flag = false
        }, interval)
      } else {
        setTimeout(() => {
          flag = false
          fn.call(this, ...args) // this 报语法错误，先用 null
        }, interval)
      }
    }
  }
}

/**
 * 判断元素是否在视口窗内
 * @param el
 * #
 */
export function checkInView(el: any) {
  // 防止父元素或自身设置display: none
  if (el.offsetParent == null) return
  // 窗口的宽高度
  const view = {
    top: 0,
    bottom: document.documentElement.clientHeight,
    left: 0,
    right: document.documentElement.clientWidth
  }
  // 绑定元素
  const rect = el.getBoundingClientRect()
  return (
    rect.right >= view.left &&
    rect.bottom >= view.top &&
    rect.left <= view.right &&
    rect.top <= view.bottom
  )
}

/**
 * search === scrollTop方法
 * @description  平滑滚动到指定区域
 * @param { node=> dom ,position=> 指定的位置}
 * node节点具备以下属性
 * overflow-y: scroll; // 设置node节点为滚动区域
 * height: 100vh;
 * -webkit-overflow-scrolling : touch; // 开启ios滚动硬件加速，否则很卡会被认为是bug
 */
export const scrollSmoothTo = (
  node: any,
  position: any,
  fn = function () {}
) => {
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function (callback) {
      return setTimeout(callback, 17)
    }
  }
  // scrollTo的兼容处理
  if (!node.scrollTop) {
    node.scrollTo = function (left: any, top: any) {
      node.scrollLeft = left
      node.scrollTop = top
    }
  }
  let scrollTop = node.scrollTop
  // 当前滚动高度
  // 滚动step方法
  const step = () => {
    // 距离目标滚动距离
    const distance = position - scrollTop
    // 兼容性处理
    if (!scrollTop) {
      window.scrollTo(0, position)
    }
    // 目标滚动位置
    scrollTop = scrollTop + distance / 5
    if (Math.abs(distance) < 1) {
      node.scrollTo(0, position)
      fn()
    } else {
      node.scrollTo(0, scrollTop)
      node.requestAnimationFrameObj = requestAnimationFrame(step)
    }
  }
  step()
}
