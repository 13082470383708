import { checkInView } from '@/utils'
import _ from 'lodash'
import { VNode } from 'vue'

export default {
  bind: (el: any, bind: any, vnode: VNode) => {
    const onlazyDecorator = (callback: any) => _.throttle(() => callback(), 200)
    if (!vnode.data?.props) {
      vnode.data!.props = {}
      Object.assign((vnode.data as any).props, { onlazyDecorator })
    }
  },
  inserted: (el: any, bind: any, vnode: VNode) => {
    if (!vnode.data?.props?.onlazyDecorator) return
    const { onlazyDecorator } = vnode.data.props
    const lazyFunc = () => {
      if (checkInView(el)) {
        const { mode, src } = bind.value || {}
        switch (mode) {
          case 'bg':
            el.style.backgroundImage = `url(${src})`
            break
          case 'img':
            el.src = src
        }
        window.removeEventListener('scroll', vnode.data?.props?.onlazy)
      }
    }
    const onlazy = onlazyDecorator(lazyFunc)
    vnode.data.props.onlazy = onlazy
    window.addEventListener('scroll', onlazy)
    onlazy()
  },
  update: (el: any, binding: any) => {
    const { mode, src } = binding.value || {}
    switch (mode) {
      case 'bg':
        el.style.backgroundImage = `url(${src})`
        break
      case 'img':
        el.src = src
    }
  },
  unbind: (el: HTMLElement, binding: any, vnode: VNode) => {
    if (vnode.data?.props) {
      delete vnode.data.props
    }
  }
}
