/* eslint-disable */
; (function (doc: any, win: any) {
  const docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      if (docEl.clientWidth > 800) {
        docEl.style.fontSize = '100%'
      } else {
        const width = docEl.clientWidth / 7.5
        docEl.style.fontSize = width + 'px'
      }
    }
  if (!doc.addEventListener) return
  win.addEventListener(resizeEvt, recalc, false)
  doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)
